.category-menu-item {
  cursor: 'pointer';

  .box-category-menu {
    color: black;
    font-weight: normal;
    padding-left: 8px;

    .category-label {
      width: 125px;
      line-height: 40px;
    }

    .category-label:hover {
      color: var(--jade);
      cursor: pointer;
      font-size: 16px;
    }
  }
}
