.manga-search {
  line-height: 15px !important;
  padding-top: 10px;
  justify-content: space-between;

  .text-author {
    padding-top: 4px;
    padding-right: 4px;
  }
}
