.action-comment {
  color: var(--jade);
}

.action-comment:hover {
  text-decoration: underline;
}
.manga {
  .hidden {
    display: none;
  }
}
