.select-input {
  outline: none !important;
  border: 1px solid var(--gray) !important;
  border-radius: 0 !important;
  padding: 3px 0px;

  .ant-select-selector {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.upload-image {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.label-upload-image {
  border: 1px solid var(--gray) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray);
}

.image-preview {
  border: 1px solid var(--gray);
  border-radius: 4px;
  padding: 16px;
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;

  &-item {
    background-color: #fff;
    padding: 6px 12px;
    border-radius: 4px;

    &-top {
      display: flex;
      justify-content: space-between;
    }

    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }

    &-name {
      font-size: 12px;
      width: 90%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      margin-bottom: 4px;
    }
  }
}

.preview-thumbnail {
  position: relative;
  border: 1px solid #ccc;

  &-close-icon {
    padding: 4px;
    border-radius: 100px;
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 22px;
    color: red;
  }
}
