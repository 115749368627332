.wrap-auth {
  height: '100%';
  background-color: #f5f5f5 !important;

  .auth-container {
    padding-top: 200px;
    justify-content: center;
    font-size: 20px !important;

    .wrap-box {
      border: 1px solid var(--color-main);
      padding: 40px;
      background-color: white;
      border-radius: 10px;
      .custom-register {
        padding-right: 40px;
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: var(--color-main);
        }
        .ant-tabs-tab:hover {
          color: var(--color-main);
        }
        .ant-tabs-ink-bar {
          background: var(--color-main);
        }
      }
    }

    .button-job {
      background-color: var(--color-main);
      color: white;
      font-weight: bold;
      cursor: pointer;
      border-radius: 4px;
      border: none !important;
      padding: 8px 16px;
      width: 100%;
    }

    .button-job:hover {
      background-color: var(--color-main);
      color: white;
    }
  }
}
