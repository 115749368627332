.title_layout {
  padding-bottom: 10px;

  .wrap_title {
    border-left: 4px solid black;
    padding-left: 10px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.title-children {
  color: var(--gray);
  font-size: 20px !important;
  font-weight: bold;
  height: 30px;
  padding-bottom: 20px;
}
