.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin: 20px 0;
}

.preview-image {
  height: 175px;
  width: 175px;

  img {
    border: 1px solid #ddd;
    border-radius: 100px;
  }
}
