.navbar-home {
  background-color: var(--color-main);
  padding: 0;
  margin: 0;
  position: sticky;
  top: 0;
  width: 100%;
  height: var(--height-navbar);
  z-index: 1;
  color: white;
}

.color-icon {
  color: white !important;
}

.notify-card {
  line-height: 20px;
  padding: 4px 8px;
  .content-notify {
    min-width: 275px;
    max-width: 275px;
    word-wrap: break-word;
    margin-bottom: 0;
    line-height: 17px;
  }
  .content-notify:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
