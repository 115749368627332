.footer {
  text-align: center;
  height: 160px;
  color: white;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  .top-box {
    height: 150px;
    background-color: #000000db;
    padding-left: 120px;
    padding-top: 15px;
  }

  .title-box {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 7px;
  }
}

.shadow-box-job {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: white;
}

.slick-prev {
  left: -50px !important;
}
.slick-next {
  right: -20px !important;
}
.slick-arrow::before {
  color: var(--color-main) !important;
  font-size: 50px !important;
}
